<template>
  <div>
    <div style="padding: 20px; height: 100%">
      <div class="df aic jcb" style="width: 100%">
        <div style="flex: 1">
          <el-form :inline="true" class="demo-form-inline">
            <div class="df aic">
              <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
                <el-input
                  placeholder="关键词"
                  v-model="query.keyword"
                  clearable
                  class="input-with-select"
                  @clear="onSubmit"
                >
                </el-input>
              </el-form-item>
              <el-button
                type="success"
                size="medium"
                @click="onSubmit"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                >查询</el-button
              >
              <el-switch
                style="margin-bottom: 20px"
                v-model="query.type"
                active-text="他方发起"
                :active-value="1"
                :inactive-value="0"
                inactive-text="我方发起"
                @change="onSubmit"
              >
              </el-switch>
            </div>
          </el-form>
        </div>
        <el-button
          type="primary"
          size="medium"
          class="mr10"
          @click="$router.go(-1)"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >返回</el-button
        >
      </div>

      <el-table :data="list" style="width: 100%">
        <el-table-column
          prop="contractName"
          label="合同名称"
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="query.type"
          prop="companyName"
          label="合同发起方"
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-else
          prop="receptCompanyName"
          label="合同接收方"
          width="300"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.receptCompanyName">
              {{ scope.row.receptCompanyName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="300" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.receptCompanyName" :style="'color:'+['#909399', '#67c23a', '#f56c6c'][scope.row.status]">
              {{ ["待通过", "通过", "未通过"][scope.row.status] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="创建时间"
          width="300"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createdTime.replace("T", " ") }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin: 0 20px"
              @click="handleDetail(scope.row)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end; padding: 20px 0">
        <el-pagination
          background
          :page-size="query.limit"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="query.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/util/api";
import { local } from "@/util/util";
import api from "@/util/extra-api";

export default {
  data() {
    return {
      query: {
        keyword: "",
        page: 1,
        limit: 10,
        userId: "",
        type: 0,
      },
      list: [],
      companyId: null,
      showAdd: false,
      // showEdit: false,
      formData: {
        name: "",
      },
      fileList: [],
      total: 0,
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  methods: {
    onSubmit() {
      this.query.page = 1;
      this.getList();
    },
    handleDetail(row) {
      if (row.url) {
        window.open(row.url);
      } else {
        this.$message.error("暂无权限导出该合同");
      }
    },
    getList() {
      api
        .get(
          `/v1/wx/contract/pcGenerateList?companyId=${this.companyId}`,
          this.query
        )
        .then((res) => {
          if (res.code === 200) {
            this.$set(
              this,
              "list",
              res.data.map((v) => {
                return {
                  ...v,
                  showEdit: false,
                };
              })
            );
            this.total = res.total;
          }
        });
    },
    pageChange(e) {
      this.query.page = e;
      this.getList();
    },
  },
};
</script>
 
<style scoped lang="scss">
/deep/.el-table__empty-block {
  width: 100% !important;
}
</style>